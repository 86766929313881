import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { NavigateBeforeSharp } from '@mui/icons-material';
import '../private-class-checkout/PrivateClassCheckout.css';
import KnetPayment from '../../knet/KnetPayment';
import ReactDOM from 'react-dom';
import { axiosPost } from '../../../backend_helper/api';
import { WEB_SOCKET_URLS } from '../../../config/apiConfig';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';

const CourseClassCheckout = () => {
    const classList = JSON.parse(sessionStorage.getItem('PRIVATE_CLASS'));
    const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    function formatDate(inputString) {
        const date = new Date(inputString);
        
        const day = date.getDate().toString().padStart(2, '0');
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const year = date.getFullYear();
        const hours = date.getHours().toString().padStart(2, '0');
        const minutes = date.getMinutes().toString().padStart(2, '0');
        
        const ampm = hours >= 12 ? 'pm' : 'am';
        const formattedHours = (hours % 12) || 12;
        
        const formattedDate = `${day}-${month}-${year} | ${formattedHours}:${minutes}${ampm} UTC`;
        
        return formattedDate;
      }

    // let dateTime = formatDate(classList.DateTime)
   

    const onPayNowClickHandler = () => {
        const payload = {};
        payload['ClassCode'] = JSON.parse(sessionStorage.getItem('PRIVATE_CLASS')).ClassLink;
        // console.log('payload: ', payload);
        axiosPost(WEB_SOCKET_URLS.GetTutorClassPaymentLink, payload)
            .then((res) => {
                const responseData = res.data.PrivateTutorPaymentLink;
                if (res.data.StatusCode === 200) {
                    const paymentURL = responseData?.PaymentURL

                    if (paymentURL) {
                        // Render the PaymentPage component with the paymentHTML
                        const paymentHTML = paymentURL; // You can use paymentURL directly
                        ReactDOM.render(
                            <KnetPayment paymentHTML={paymentHTML} />,
                            document.getElementById('root')
                        );
                    }
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };



    return (
        <>
            <div className='checkOutPage'>
                <div className='checkoutPageSec'>
                    <img src='./images/bg-element-1.png' alt='' className='bg-element-1' />
                    <img src='./images/bg-element-2.png' alt='' className='bg-element-2' />
                    <div className='checkOutPageInner'>
                        <Link to={`/student-course-class/${sessionStorage.getItem('LINK_SESSION')}`} className='text-btn'><NavigateBeforeSharp />  Back </Link>
                        <h2 className='classheading'>Payment</h2>
                        <div className='checkoutCard'>
                            <div className='card-body'>
                                <div className='classSec'>
                                    <h4>{classList?.CourseName}</h4>
                                    {/* <div className='durationTime'>
                                        <p><AccessTimeIcon /> Duration <span>{classList?.Duration}</span></p>
                                        <p><CalendarTodayIcon /> Date & Time <span>{new Date(classList?.DateTime).toLocaleString('en-US', { timeZone: userTimeZone, day: '2-digit', month: '2-digit', year: 'numeric', hour: 'numeric', minute: 'numeric', hour12: true })}</span></p>
                                    </div> */}
                                </div>
                                <div className='couponSec'>
                                    <h3>Add Coupon Code</h3>
                                    <div className='promoCodeSec'>
                                        <input type="text" placeholder='Enter promo code' />
                                        <button>Apply</button>
                                    </div>
                                </div>
                                <div className='discountSec'>
                                    <p>One Hour Session <span>{parseInt(classList?.Price) + ' KD'}</span></p>
                                    <p>Transaction Fees <span>{parseInt(classList?.TransactionFees) + ' KD'}</span></p>
                                    {/* <p>One Hour Session <span>{classList?.Price} {classList?.CurrencyType}</span></p> */}
                                    <p>Discount Applied <span>0 KD</span></p>
                                </div>
                            </div>
                            <div className='totalPaymentSec'>
                                <h2>Total Payment</h2>
                                {/* <h2>{classList?.Price} {classList?.CurrencyType}</h2> */}
                                <h2>{`${parseInt(classList?.Price) + parseInt(classList?.TransactionFees)} KD`} </h2>
                            </div>
                            <div className='paybtnSec'>
                                <button className='paybtn' onClick={onPayNowClickHandler}>Pay Now</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default CourseClassCheckout
