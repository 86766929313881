import React, { useEffect, lazy, Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { Route, BrowserRouter, Routes } from 'react-router-dom';
import { createTheme } from '@mui/material/styles';
import { ThemeProvider } from '@mui/material';
import ProtectedRoute from './auth-guard';
import Loading from './components/loading/Loading';
import PrivateClassCheckout from './pages/add-class/private-class-checkout/PrivateClassCheckout';
import CourseClassCheckout from './pages/add-class/course-class-checkout/CourseClassCheckout';
import { refreshAuthToken } from './firebase/firebase';



const Login = lazy(() => import('./pages/login/Login'));
const CommonSignUp = lazy(() => import('./pages/sign-up/CommonSignUp'));
const ForgetPassword = lazy(() => import('./pages/forget-password/ForgetPassword'));
const TutorRegistration = lazy(() => import('./pages/registration/TutorRegistration'));
const StudentRegistration = lazy(() => import('./pages/registration/StudentRegistration'));
const DashboardStudent = lazy(() => import('./pages/dashboard/DashboardStudent'));
const DashboardTutor = lazy(() => import('./pages/dashboard/DashboardTutor'));
const TutorSearch = lazy(() => import('./pages/tutor-search/TutorSearch'));
const TutorPaymentHistory = lazy(() => import('./pages/payments/tutor-transactions/TutorPaymentHistory'));
const StudentPaymentHistory = lazy(() => import('./pages/payments/student-transactions/StudentPaymentHistory'));
const AddBank = lazy(() => import('./pages/add-bank/AddBank'))
const CheckOut = lazy(() => import('./pages/checkout/Checkout'));
const LoginAs = lazy(() => import('./pages/login/LoginAs'));
const Home = lazy(() => import('./pages/home/Home'));
const StudentPersonalDetail = lazy(() => import('./pages/personel-details/StudentPersonalDetail'));
const TutorPersonalDetail = lazy(() => import('./pages/personel-details/TutorPersonalDetail'));
const LaunchClassRoom = lazy(() => import('./components/agora/LaunchClassRoom'));
const TutorCashWithdrawal = lazy(() => import('./pages/payments/tutor-transactions/TutorCashWithdrawal'));
const StudentAccount = lazy(() => import('./pages/accounts/StudentAccount'));
const TutorAccount = lazy(() => import('./pages/accounts/TutorAccount'));
const KnetPayment = lazy(() => import('./pages/knet/KnetPayment'));
const ClassCheckout = lazy(() => import('./pages/add-class/class-checkout/ClassCheckout'));
const ClassRoom = lazy(() => import('./pages/add-class/class-room/ClassRoom'));
const StudentClass = lazy(()=> import('./pages/add-class/student-class/StudentClass'));
const StudentClassSignUp = lazy(()=> import('./pages/add-class/student-class-signup/StudentClassSignUp'));
const StudentPrivateClassRegistration = lazy(()=> import('./pages/add-class/student-class-signup/StudentPrivateClassRegistrationForm'));
const StudentPrivateClass = lazy(()=> import('./pages/add-class/student-private-class/StudentPrivateClass'));
const StudentCourse = lazy(()=> import('./pages/add-class/student-courses/StudentCourse'));
const StudentSessions = lazy(()=> import('./pages/add-class/student-courses/StudentSessions'));
const LaunchPrivateClassRoom = lazy(()=> import('./components/agora/LaunchPrivateCallsRoom'));
const LaunchCourseCallsRoom = lazy(()=> import('./components/agora/LaunchCourseCallsRoom'));
const LaunchInstantClassRoom = lazy(()=> import('./components/agora/LaunchInstantClassRoom'));
const InstantClass = lazy(() => import('./pages/instant-class/InstantClass'));
const PrivateClass = lazy(() => import('./pages/private-class/PrivateClass'));
const ManageSession = lazy(() => import('./pages/private-class/ManageSession'));


const theme = createTheme({
    palette: {
        primary: {
            main: '#20C868',
            contrastText: '#FFFfff'
        },
        secondary: {
            main: '#024751',
            contrastText: '#FFFfff'
        },
    },
});


export default function App() {

    useEffect(() => {
        // Set up an interval to refresh the token every 20 minutes
        const refreshInterval = 20 * 60 * 1000; // 20 minutes in milliseconds
        const intervalId = setInterval(() => {
          refreshAuthToken();
        }, refreshInterval);
    
        // Clean up the interval on component unmount
        return () => {
          clearInterval(intervalId);
        };
    }, []);

    return (
        <ThemeProvider theme={theme}>
            <BrowserRouter>
                <Routes>
                    {/* <Route path="/" element={<Home />} /> */}
                    {/* <Route path="/login-as" element={<LoginAs />} /> */}
                    <Route path="/" element={<LoginAs />} />
                    <Route path="/login" element={<Login />} />
                    <Route path="/signup" element={<CommonSignUp />} />
                    <Route path="/forget-password" element={<ForgetPassword />} />
                    <Route path="/class-checkout" element={<ClassCheckout />} />
                    <Route path="/class-room" element={<ClassRoom />} />
                    <Route path="/:session" element={<StudentClass iscourses={false} />} />
                    <Route path="/course/:session" element={<StudentClass iscourses={true} />} />
                    <Route path="/student-signup/:session" element={<StudentClassSignUp />} />
                    <Route path="/registration/:session" element={<StudentPrivateClassRegistration />} />
                    <Route path="/student-private-class/:session" element={
                        <ProtectedRoute>
                            <StudentPrivateClass />
                        </ProtectedRoute>
                    } />
                    <Route path="/student-course-class/:session" element={
                        <ProtectedRoute>
                            <StudentCourse />
                        </ProtectedRoute>
                    } />
                    <Route path="/student-course-class/sessions-schedule/:ClassID" element={
                        <ProtectedRoute>
                            <StudentSessions />
                        </ProtectedRoute>
                    } />
                    <Route path="/private-class-checkout/:session" element={
                        <ProtectedRoute>
                            <PrivateClassCheckout />
                        </ProtectedRoute>
                    } />
                    <Route path="/course-class-checkout/:session" element={
                        <ProtectedRoute>
                            <CourseClassCheckout />
                        </ProtectedRoute>
                    } />
                    <Route path="/tutor-registration" element={
                        <ProtectedRoute>
                            <TutorRegistration />
                        </ProtectedRoute>
                    } />
                    <Route path="/student-registration" element={
                        <ProtectedRoute>
                            <StudentRegistration />
                        </ProtectedRoute>
                    } />
                    <Route path="/student-dashboard" element={
                        <ProtectedRoute>
                            <DashboardStudent />
                        </ProtectedRoute>
                    } />
                    <Route path="/tutor-dashboard" element={
                        <ProtectedRoute>
                            <DashboardTutor />
                        </ProtectedRoute>
                    } />
                    <Route path="/tutor-search" element={
                        <ProtectedRoute>
                            <TutorSearch />
                        </ProtectedRoute>
                    } />
                    <Route path="/student-account" element={
                        <ProtectedRoute>
                            <StudentAccount />
                        </ProtectedRoute>
                    } />
                    <Route path="/tutor-account" element={
                        <ProtectedRoute>
                            <TutorAccount />
                        </ProtectedRoute>
                    } />
                    <Route path="/tutor-payment-history" element={
                        <ProtectedRoute>
                            <TutorPaymentHistory />
                        </ProtectedRoute>
                    } />
                    <Route path="/student-payment-history" element={
                        <ProtectedRoute>
                            <StudentPaymentHistory />
                        </ProtectedRoute>
                    } />
                    <Route path="/tutor-cash-withdrawal" element={
                        <ProtectedRoute>
                            <TutorCashWithdrawal />
                        </ProtectedRoute>
                    } />
                    <Route path="/add-bank" element={
                        <ProtectedRoute>
                            <AddBank />
                        </ProtectedRoute>
                    } />
                    <Route path="/checkout-page" element={
                        <ProtectedRoute>
                            <CheckOut />
                        </ProtectedRoute>
                    } />
                    <Route path="/student-profile" element={
                        <ProtectedRoute>
                            <StudentPersonalDetail />
                        </ProtectedRoute>
                    } />
                    <Route path="/tutor-profile" element={
                        <ProtectedRoute>
                            <TutorPersonalDetail />
                        </ProtectedRoute>
                    } />
                    <Route
                        path="/classroom/:encryptSessionId"
                        element={
                            <ProtectedRoute>
                                <LaunchClassRoom />
                            </ProtectedRoute>
                        }
                    />
                      <Route
                        path="/private-class/:encryptSessionId"
                        element={
                            <ProtectedRoute>
                                <LaunchPrivateClassRoom />
                            </ProtectedRoute>
                        }
                    />
                      <Route
                        path="/course-class/:encryptSessionId"
                        element={
                            <ProtectedRoute>
                                <LaunchCourseCallsRoom />
                            </ProtectedRoute>
                        }
                    />
                    <Route
                        path="/tutor-instant-class/:encryptSessionId"
                        element={<InstantClass /> }
                    />
                    <Route
                        path="/student-instant-class/:encryptSessionId"
                        element={<InstantClass /> }
                    />
                    <Route
                        path="/instant-classroom/:encryptSessionId/:roleId"
                        element={<LaunchInstantClassRoom /> }
                    />
                    <Route
                        path="/course-class"
                        element={
                            <ProtectedRoute>
                                <PrivateClass />
                            </ProtectedRoute>
                        }
                    />
                    
                    <Route
                        path="/course-class/managesession/:ClassID"
                        element={
                            <ProtectedRoute>
                                <ManageSession />
                            </ProtectedRoute>
                        }
                    />

                    <Route
                        path="/payment"
                        element={
                            <ProtectedRoute>
                                <KnetPayment />
                            </ProtectedRoute>
                        }
                    />
                    
                </Routes>
            </BrowserRouter>
        </ThemeProvider>
    );
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <Suspense fallback={<Loading />}>
        <App />
    </Suspense>
);
